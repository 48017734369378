import { defineStore } from "pinia";
import { api } from "../boot/axios";
import { router } from '../routes';

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    returnUrl: null,
  }),
  actions: {
    async login(model) {
     const {email, password} = model;
     const user = await api.post("/login/", {
        email,
        password,
      });
      if (user.data.status == 'error') {
        throw new Error(user.data.message);
      }
      this.user = user.data;
      localStorage.setItem("user", JSON.stringify(user.data));
      api.defaults.headers.common['Authorization'] = 'bearer ' + user.token;
      router.push(this.returnUrl || "/");
    },
    async register(model) {
      const {first_name, last_name, email, password} = model;
      const user = await api.post("/register", {
        first_name,
        last_name,
        email,
        password,
      });
      if (user.data.status == 'error') {
        throw new Error(user.data.message);
      }
      this.user = user.data;
      localStorage.setItem("user", JSON.stringify(user.data));
      api.defaults.headers.common['Authorization'] = 'bearer ' + user.token;
      router.push(this.returnUrl || "/");
    },
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      router.push("/auth");
    },
  },
});
