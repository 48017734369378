import axios from "axios";
import { useAuthStore } from "../stores";

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
export const api = axios.create({ baseURL: "https://tintedge.vitegan.com" });

api.interceptors.request.use(function (config) {
    // Do something before request is sent
    const authStore = useAuthStore();
    if (authStore.user && authStore.user.token) {
      config.headers['Authorization'] = authStore.user.token;
    }
    return config;
  }, function (error) {
    console.log('req', error);
    // Do something with request error
    return Promise.reject(error);
  });

  api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log(response);
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log('res', error.response.status);
    if (error.response && error.response.status == 401) {
      const authStore = useAuthStore();
      return authStore.logout();
    }
    return Promise.reject(error);
  });